"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useFormGroup;

var React = _interopRequireWildcard(require("react"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/**
 * @experimental
 */
function useFormGroup(_ref) {
  var _ref$initialKeys = _ref.initialKeys,
      initialKeys = _ref$initialKeys === void 0 ? [] : _ref$initialKeys;

  var _React$useState = React.useState(initialKeys),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      keys = _React$useState2[0],
      setKeys = _React$useState2[1];

  var formsRef = React.useRef(Object.fromEntries(initialKeys.map(function (key) {
    return [key, null];
  })));

  var _React$useState3 = React.useState([]),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      aliases = _React$useState4[0],
      setAliases = _React$useState4[1];

  var _React$useState5 = React.useState(false),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      hasLastErrors = _React$useState6[0],
      setHasLastErrors = _React$useState6[1];

  var _React$useState7 = React.useState(false),
      _React$useState8 = _slicedToArray(_React$useState7, 2),
      loading = _React$useState8[0],
      setLoading = _React$useState8[1];

  var addKey = React.useCallback(function (key) {
    var forms = _objectSpread(_objectSpread({}, formsRef.current), {}, _defineProperty({}, key, null));

    setKeys(Object.keys(forms));
    formsRef.current = forms;
  }, []);
  var setAllLoading = React.useCallback(function setAllLoading(value) {
    setLoading(value);

    var _iterator = _createForOfIteratorHelper(keys),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _key = _step.value;
        var form = formsRef.current[_key];
        form === null || form === void 0 ? void 0 : form.setLoading(value);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }, [keys]);
  var submit = React.useCallback(async function submit() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        whitelistKeys = _ref2.whitelistKeys,
        onFinish = _ref2.onFinish;

    setAllLoading(true);
    var hasErrors = false;

    var _iterator2 = _createForOfIteratorHelper(keys),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var _key2 = _step2.value;
        var form = formsRef.current[_key2];
        if (!form) continue;
        if (whitelistKeys && !whitelistKeys.includes(_key2)) continue;
        if (!form.hasDirty) continue;
        var result = await form.handleSubmit();
        form.setLoading(true); // There were errors on a form

        if (!result) {
          hasErrors = true;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }

    setHasLastErrors(hasErrors);
    setAllLoading(false);
    onFinish === null || onFinish === void 0 ? void 0 : onFinish({
      hasErrors: hasErrors
    });
    return !hasErrors;
  }, [keys]);

  var _getItemControls = React.useCallback(function (key) {
    return {
      aliases: aliases.filter(function (a) {
        return a[0] === key;
      }).map(function (a) {
        return a[1];
      }),
      setForm: function setForm(form) {
        formsRef.current = _objectSpread(_objectSpread({}, formsRef.current), {}, _defineProperty({}, key, form));
      },
      useSetForm: function useSetForm(form) {
        React.useEffect(function () {
          formsRef.current = _objectSpread(_objectSpread({}, formsRef.current), {}, _defineProperty({}, key, form));
        }, [form]);
      },
      remove: function remove() {
        // No need to remove old forms
        var forms = Object.fromEntries(Object.entries(formsRef.current).filter(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 1),
              k = _ref4[0];

          return k !== key;
        }));
        formsRef.current = forms;
        setKeys(Object.keys(forms));
      },
      addAlias: function addAlias(alias) {
        setAliases([].concat(_toConsumableArray(aliases), [[key, alias]]));
      }
    };
  }, [aliases]);

  var hasKeyOrAlias = React.useCallback(function (keyOrAlias) {
    if (keys.includes(keyOrAlias)) return true;

    if (keys.some(function (key) {
      return _getItemControls(key).aliases.includes(keyOrAlias);
    })) {
      return true;
    }

    return false;
  }, [aliases, keys]);
  return {
    addKey: addKey,
    hasKeyOrAlias: hasKeyOrAlias,
    keys: keys,
    // replicating form API
    isLoading: loading,
    setLoading: setAllLoading,
    hasLastErrors: hasLastErrors,
    submit: submit,
    // internal
    _getItemControls: _getItemControls
  };
}