"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reducer;

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function reducer(state, action) {
  switch (action.type) {
    case 'VALUE_CHANGE':
      {
        var _action$payload = action.payload,
            name = _action$payload.name,
            value = _action$payload.value,
            errors = _action$payload.errors;
        return _objectSpread(_objectSpread({}, state), {}, {
          values: _objectSpread(_objectSpread({}, state.values), {}, _defineProperty({}, name, value)),
          dirtyFields: [].concat(_toConsumableArray(state.dirtyFields.filter(function (d) {
            return d !== name;
          })), [name])
        }, errors && {
          errors: errors
        });
      }

    case 'SUBMIT':
      return _objectSpread(_objectSpread({}, state), {}, {
        errors: action.payload.errors,
        lastErrors: action.payload.errors,
        dirtyFields: []
      });

    case 'RESET':
      return _objectSpread(_objectSpread({}, action.payload), {}, {
        lastErrors: {},
        dirtyFields: [],
        loading: false
      });

    case 'SET_LOADING':
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: action.payload
      });

    case 'ADD_SUBMIT_ERROR':
      {
        var _action$payload2 = action.payload,
            _name = _action$payload2.name,
            error = _action$payload2.error;
        return _objectSpread(_objectSpread({}, state), {}, {
          errors: _objectSpread(_objectSpread({}, state.errors), {}, _defineProperty({}, _name, [].concat(_toConsumableArray(state.errors[_name] || []), [error]))),
          lastErrors: _objectSpread(_objectSpread({}, state.lastErrors), {}, _defineProperty({}, _name, [].concat(_toConsumableArray(state.lastErrors[_name] || []), [error])))
        });
      }

    default:
      action;
      return state;
  }
}