"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = useForm;

var React = _interopRequireWildcard(require("react"));

var _reducer = _interopRequireDefault(require("./core/reducer"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function useForm(_ref) {
  var _ref$defaultValues = _ref.defaultValues,
      defaultValues = _ref$defaultValues === void 0 ? {} : _ref$defaultValues,
      onSubmit = _ref.onSubmit,
      pristineValues = _ref.pristineValues,
      validator = _ref.validator,
      alwaysRevalidateOnChange = _ref.alwaysRevalidateOnChange,
      revalidateFields = _ref.revalidateFields,
      preValidateTransform = _ref.preValidateTransform,
      postValidateTransform = _ref.postValidateTransform;

  function determineErrors(values) {
    var _preValidateTransform;

    if (!validator) return _objectSpread({}, null);
    var transformed = (_preValidateTransform = preValidateTransform === null || preValidateTransform === void 0 ? void 0 : preValidateTransform(values)) !== null && _preValidateTransform !== void 0 ? _preValidateTransform : values;
    /* eslint-disable flowtype/no-weak-types */

    return Object.fromEntries(Object.entries(validator(transformed)).filter(function (_ref2) {
      var _ref3 = _slicedToArray(_ref2, 2),
          v = _ref3[1];

      return (v === null || v === void 0 ? void 0 : v.length) > 0;
    }));
    /* eslint-enable flowtype/no-weak-types */
  }

  var _React$useReducer = React.useReducer(_reducer.default, {
    values: defaultValues,
    errors: determineErrors(defaultValues),
    lastErrors: {},
    dirtyFields: Object.keys(defaultValues),
    loading: false
  }, undefined),
      _React$useReducer2 = _slicedToArray(_React$useReducer, 2),
      state = _React$useReducer2[0],
      dispatch = _React$useReducer2[1];

  function handleValueChange(name, value) {
    var errors = alwaysRevalidateOnChange || revalidateFields !== null && revalidateFields !== void 0 && revalidateFields.includes(name) ? determineErrors(_objectSpread(_objectSpread({}, state.values), {}, _defineProperty({}, name, value))) : undefined;
    dispatch({
      type: 'VALUE_CHANGE',
      payload: {
        name: name,
        value: value,
        errors: errors
      }
    });
  }

  function getFieldProp(name) {
    var pristine = pristineValues;
    var value = state.values[name];

    if (value === undefined && pristine && pristine[name] !== undefined) {
      value = pristine[name];
    } // Add spaces and capitalize for label


    var label = name.replace(/([a-z])([A-Z])/g, '$1 $2');
    label = label.charAt(0).toUpperCase() + label.slice(1);
    return {
      name: name,
      label: label,
      value: value,
      handleValueChange: handleValueChange.bind(null, name),
      isDirty: state.dirtyFields.includes(name),
      isLoading: state.loading,
      errorList: state.errors[name],
      lastErrorList: state.lastErrors[name]
    };
  }
  /**
   * Keep this async and return true on successful submit so that users can
   * await it.
   */


  async function handleSubmit() {
    var errors = determineErrors(state.values);
    dispatch({
      type: 'SUBMIT',
      payload: {
        errors: errors
      }
    });
    if (Object.keys(errors).length > 0) return false;

    var transformed = (preValidateTransform || function (x) {
      return x;
    })(state.values);

    transformed = (postValidateTransform || function (x) {
      return x;
    })(transformed);

    var result = await onSubmit(transformed, prepareFormProp());
    return result === false ? false : true;
  }

  function prepareFormProp() {
    return {
      getField: getFieldProp,
      handleSubmit: handleSubmit,
      isLoading: state.loading,
      setLoading: function setLoading(value) {
        return dispatch({
          type: 'SET_LOADING',
          payload: value
        });
      },
      addSubmitError: function addSubmitError(name, error) {
        dispatch({
          type: 'ADD_SUBMIT_ERROR',
          payload: {
            name: name,
            error: error
          }
        });
      },
      formErrorList: state.lastErrors._form || [],
      values: state.values,
      reset: function reset() {
        return dispatch({
          type: 'RESET',
          payload: {
            values: defaultValues,
            errors: determineErrors(defaultValues)
          }
        });
      },
      errors: state.errors,
      lastErrors: state.lastErrors,
      hasErrors: Object.keys(state.errors).length > 0,
      hasLastErrors: Object.keys(state.lastErrors).length > 0,
      dirtyFields: state.dirtyFields,
      hasDirty: state.dirtyFields.length > 0
    };
  }

  return prepareFormProp();
}